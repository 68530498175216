body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

@media print {
    /* chaburos/id/payments/id not printing scrollable */
 
    #alert-dialog-title, .noPrint{
        display: none !important;
    } 
    .td-print {
        width: 25px !important;
        padding: 4px 15px 4px 24px !important;
    }
    .parentOf-printPage{
        width: fit-content !important;
    }
}
